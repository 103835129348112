import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SelectElement from "../../components/SelectElement/MainSelect";
import StepsList from "../../components/StepsList/StepsList";
import DocumentViewer from "../../components/DocumentViewer/DocumentViewer";
import { HiMiniArrowDownTray } from "react-icons/hi2";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import moment from "moment";
import { HiMiniCheck } from "react-icons/hi2";
import { Toastify } from "../../components/Toastify/Toastify";
import {
  downloadDeclarations,
  getPreviewDeclarations,
  getStepsIssueDeclarations
} from "../../services/declarationsService/declarations.service";
import Cookies from "js-cookie";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { getListStudentCourses } from "../../services/courseService/course.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";

function DeclarationsPage() {
  const [declarationOptions, setDeclarationOptions] = useState([
    { value: "declarationRegistration", label: "Declaração de Matrícula" },
    { value: "declarationStudying", label: "Declaração de Cursando" },
    { value: "declarationStudyingHistoric", label: "Declaração com Histórico" },
    { value: "declarationConclusion", label: "Declaração de Conclusão" }
  ]);

  const [isLoading, setIsLoading] = useState(true);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [selectedCourseOption, setSelectedCourseOption] = useState({});
  const [stepsIssue, setStepsIssue] = useState({});
  const [stepOptions, setStepOptions] = useState([]);
  const [stepStatus, setStatusStep] = useState([]);
  const [mobileScreen, setMobileScreen] = useState(1);
  const [windowWidth, setWindowWidth] = useState(0);
  const [navBar, setNavBar] = useState(0);

  const [selectedDeclarationOption, setSelectedDeclarationOption] = useState(
    declarationOptions[0]
  );
  const [emissionStatus, setEmissionStatus] = useState(false);
  const [previewDeclarationImg, setPreviewDeclarationImg] = useState(null);
  const [navBarHeader, setNavBarHeader] = useState(0);

  useEffect(() => {
    async function fetchStudentCourses() {
      setIsLoading(true);
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        setStudentInfos(student);
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId
        });

        if (coursesResponse.status === 200) {
          const allCourses = coursesResponse.data
            .filter((course) => course.areaName !== "Softskills")
            .map((course) => ({
              label: course.courseName,
              value: course.courseAlias,
              courseId: course.courseId,
              matriculationId: course.matriculationId,
              typeCourse: course.typeCourse,
              matriculationDate: course.matriculationDate,
              courseCompleted: course.courseCompleted
            }));
          setCoursesOptions(allCourses);
          if (allCourses.length > 0) {
            const lastCourseAccessed = Cookies.get(
              "lastCourseAccessed_declaration"
            );
            if (lastCourseAccessed) {
              const lastCourse = allCourses.find(
                (course) => course.value === lastCourseAccessed
              );
              if (lastCourse) {
                setSelectedCourseOption(lastCourse);
              } else {
                setSelectedCourseOption(allCourses[0]);
              }
            } else {
              setSelectedCourseOption(allCourses[0]);
            }
          } else {
            Toastify("error", "Você não possui nenhum curso.");
          }
        } else {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
      }
    }

    fetchStudentCourses();
  }, []);

  const handleSelectCourseChange = (e) => {
    setMobileScreen(1);
    setSelectedCourseOption(e);
    Cookies.set("lastCourseAccessed_declaration", e.value, {
      expires: 1 / 24
    });
  };

  const handleSelectDeclarationChange = async (e) => {
    if (selectedDeclarationOption === e) return;
    await displayStep(stepsIssue, e.value);
    setMobileScreen(1);
    setSelectedDeclarationOption(e);
  };

  const downloadDeclaration = async (data) => {
    setIsLoading(true);
    try {
      let response = await downloadDeclarations(data);
      if (response.status === 200) {
        const tempLink = document.createElement("a");
        tempLink.href = URL.createObjectURL(response.data);
        tempLink.setAttribute("download", `${String(data.name)}.pdf`);
        tempLink.click();
      } else {
        console.error("Erro ao baixar o contrato");
        Toastify(
          "error",
          "Erro ao baixar o conteúdo. Tente novamente mais tarde."
        );
      }
    } catch (error) {
      console.error("Erro ao baixar o contrato", error);
      Toastify(
        "error",
        "Erro ao baixar o conteúdo. Tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const previewDeclaration = async (type, status) => {
    setPreviewDeclarationImg(null);
    setIsLoading(true);

    let data = {
      matriculationId: selectedCourseOption.matriculationId,
      courseId: selectedCourseOption.courseId,
      type: type,
      sessionId: studentInfos.sessionId,
      status: !status
    };
    try {
      let response = await getPreviewDeclarations(data);
      if (response.status === 200) {
        setPreviewDeclarationImg(response.data.img);
      } else {
        setPreviewDeclarationImg(null);
      }
    } catch (error) {
      setPreviewDeclarationImg(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function callHandledeclaration() {
      await handleDeclaration();
    }
    callHandledeclaration();
    // eslint-disable-next-line
  }, [selectedCourseOption]);

  const handleDeclaration = async () => {
    try {
      if (selectedCourseOption && studentInfos) {
        setSelectedDeclarationOption(declarationOptions[0]);

        let declarationOptionsData = [
          {
            value: "declarationRegistration",
            label: "Declaração de Matrícula"
          },
          { value: "declarationStudying", label: "Declaração de Cursando" },
          {
            value: "declarationStudyingHistoric",
            label: "Declaração com Histórico"
          },
          { value: "declarationConclusion", label: "Declaração de Conclusão" }
        ];

        if (
          moment(new Date("2023/06/01")) <
          moment(selectedCourseOption.matriculationDate)
        ) {
          declarationOptionsData.push({
            value: "declarationFinance",
            label: "Declaração Financeira"
          });
        }
        if (selectedCourseOption.courseCompleted) {
          declarationOptionsData = declarationOptionsData.filter(
            (option) =>
              option.value !== "declarationRegistration" &&
              option.value !== "declarationStudying" &&
              option.value !== "declarationStudyingHistoric"
          );
        }
        
        setSelectedDeclarationOption(declarationOptionsData[0]);
        setDeclarationOptions(declarationOptionsData);

        const declarationData = {
          studentInfos,
          selectedCourseOption,
          type: declarationOptionsData[0].value
        };

        const steps = await getStepsIssueDeclarations(declarationData);
        if (steps.status === 200) {
          setStepsIssue(steps.data);
          await displayStep(steps.data, declarationOptionsData[0].value);
        } else {
          console.error("Erro ao obter steps", steps);
          Toastify(
            "error",
            "Erro ao obter as informações. Tente novamente mais tarde."
          );
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Erro ao obter info", err);
      Toastify(
        "error",
        "Erro ao obter as informações. Tente novamente mais tarde."
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayStep = async (data, type) => {
    let dataStep = [];
    let dataStatus = [];

    setEmissionStatus(false);
    let statusStep = false;

    if (type === "declarationRegistration") {
      const doc = stepDocument(data.documents);
      const contract = stepContract(data.documents);

      dataStatus.push({
        name: "doc",
        status: doc.status,
        routerButton: "/user/documents"
      });
      dataStatus.push({
        name: "contract",
        status: contract.status,
        routerButton: "/user/contracts"
      });

      statusStep = doc.status && contract.status ? true : false;
      dataStep.push(doc.data, contract.data);
    } else if (
      type === "declarationStudyingHistoric" ||
      type === "declarationStudying"
    ) {
      const doc = stepDocument(data.documents);
      const contract = stepContract(data.documents);
      const seven = stepTime7days(data.minTime);

      dataStatus.push({
        name: "doc",
        status: doc.status,
        routerButton: "/user/documents"
      });
      dataStatus.push({
        name: "contract",
        status: contract.status,
        routerButton: "/user/contracts"
      });
      dataStatus.push({
        name: "seven",
        status: seven.status,
        routerButton: "/studentCourses"
      });

      statusStep = doc.status && contract.status && seven.status ? true : false;
      dataStep.push(doc.data, contract.data, seven.data);
    } else if (type === "declarationConclusion") {
      const doc = stepDocument(data.documents);
      const contract = stepContract(data.documents);
      const minTime = stepMinTime(data.minTime);
      const av = stepAvaliations(data.avaliation);
      const tcc = stepTcc(data.avaliation);
      statusStep =
        doc.status &&
        contract.status &&
        minTime.status &&
        av.status &&
        tcc.status
          ? true
          : false;

      dataStatus.push({
        name: "doc",
        status: doc.status,
        routerButton: "/user/documents"
      });
      dataStatus.push({
        name: "contract",
        status: contract.status,
        routerButton: "/user/contracts"
      });
      dataStatus.push({
        name: "minTime",
        status: minTime.status,
        routerButton: "/studentCourses"
      });
      dataStatus.push({
        name: "av",
        status: av.status,
        routerButton: "/avaliations"
      });
      dataStatus.push({
        name: "tcc",
        status: tcc.status,
        routerButton: "/tcc"
      });

      dataStep.push(doc.data, contract.data, minTime.data, av.data, tcc.data);
    } else if (type === "declarationFinance") {
      const doc = stepDocument(data.documents);
      const contract = stepContract(data.documents);
      statusStep = doc.status && contract.status ? true : false;

      dataStatus.push({
        name: "doc",
        status: doc.status,
        routerButton: "/user/documents"
      });
      dataStatus.push({
        name: "contract",
        status: contract.status,
        routerButton: "/user/contracts"
      });

      dataStep.push(doc.data, contract.data);
    }

    function stepDocument(dataDocument) {
      let docStatus =
        dataDocument.address === "approved" &&
        dataDocument.graduation === "approved" &&
        dataDocument.identification === "approved"
          ? true
          : false;

      return {
        status: docStatus,
        data: {
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm">
                {docStatus ? "Documentos" : "Aguardando os documentos"}
              </span>
              <span className="text-xs text-textSecondary">
                {docStatus
                  ? "Documentos pessoais aprovados."
                  : "É necessário enviar seus documentos pessoais e serem aprovados."}
              </span>
            </div>
          ),
          activated: docStatus,
          icon: docStatus ? <HiMiniCheck className="text-xl" /> : ""
        }
      };
    }

    function stepContract(dataContract) {
      let contractStatus = dataContract.contract;

      return {
        status: contractStatus,
        data: {
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm">
                {contractStatus ? "Contrato" : "Aguardando o contrato"}
              </span>
              <span className="text-xs text-textSecondary">
                {contractStatus
                  ? "Contrato assinado."
                  : "É necessário ter efetivado a assinatura do contrato."}
              </span>
            </div>
          ),
          activated: contractStatus,
          icon: contractStatus ? <HiMiniCheck className="text-xl" /> : ""
        }
      };
    }

    function stepTime7days(dataTime) {
      let sevenDaysStatus = dataTime.sevenDays;

      return {
        status: sevenDaysStatus,
        data: {
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm">
                {sevenDaysStatus ? "Tempo" : "Aguardando o tempo"}
              </span>
              <span className="text-xs text-textSecondary">
                {sevenDaysStatus
                  ? "Tempo mínimo completo."
                  : "É necessário ter cursado pelo menos 7 dias de curso."}
              </span>
            </div>
          ),
          activated: sevenDaysStatus,
          icon: sevenDaysStatus ? <HiMiniCheck className="text-xl" /> : ""
        }
      };
    }

    function stepMinTime(dataTime) {
      let minTimeStatus = dataTime.status;

      return {
        status: minTimeStatus,
        data: {
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm">
                {minTimeStatus ? "Tempo" : "Aguardando o tempo"}
              </span>
              <span className="text-xs text-textSecondary">
                {minTimeStatus
                  ? "Tempo mínimo completo."
                  : "É necessário ter o tempo mínimo completo."}
              </span>
            </div>
          ),
          activated: minTimeStatus,
          icon: minTimeStatus ? <HiMiniCheck className="text-xl" /> : ""
        }
      };
    }

    function stepAvaliations(avaliation) {
      let avaliationsStatus =
        avaliation.avaliationsReproved === 0 &&
        avaliation.avaliationsNotSend === 0 &&
        avaliation.avaliationsApproved > 0 &&
        avaliation.avaliationAvf
          ? true
          : false;

      return {
        status: avaliationsStatus,
        data: {
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm">
                {avaliationsStatus
                  ? "Avaliações"
                  : "Aguardando a conclusão das avaliações"}
              </span>
              <span className="text-xs text-textSecondary">
                {avaliationsStatus
                  ? "Avaliações realizadas."
                  : "É necessário realizar todas as avaliações."}
              </span>
            </div>
          ),
          activated: avaliationsStatus,
          icon: avaliationsStatus ? <HiMiniCheck className="text-xl" /> : ""
        }
      };
    }

    function stepTcc(tcc) {
      let tccStatus = tcc.tccDispense ? true : tcc.tcc ? true : false;

      return {
        status: tccStatus,
        data: {
          content: (
            <div className="flex flex-col gap-1.5">
              <span className="font-semibold text-sm">
                {tccStatus ? "TCC" : "Aguardando o TCC"}
              </span>
              <span className="text-xs text-textSecondary">
                {tccStatus
                  ? "TCC regularizado."
                  : "É necessário fazer o TCC ou dispensá-lo."}
              </span>
            </div>
          ),
          activated: tccStatus,
          icon: tccStatus ? <HiMiniCheck className="text-xl" /> : ""
        }
      };
    }

    let dataStatusStepFalse = [];

    for (const status of dataStatus) {
      if (status.status === false) {
        dataStatusStepFalse.push(status);
      }
    }

    setStepOptions(dataStep);
    setStatusStep(dataStatusStepFalse);
    setEmissionStatus(statusStep);
    previewDeclaration(type, statusStep);
  };

  const handleOpenInfo = () => {
    // const mobileScreenNum = mobileScreen;
    // if (mobileScreenNum === 1) setMobileScreen(2);
    // if (mobileScreenNum === 2) setMobileScreen(1);
    setMobileScreen(mobileScreen === 1 ? 2 : 1);
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <HeaderBar
        courseNavigation={false}
        actualPage="Documentos"
        mobileMainText="Declarações"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Documentos" />
      <LoadingSection isLoading={isLoading} />

      {/* 
      style={{ height: windowWidth < 960 ? windowHeight : `100%` }}
        className={`overflow-hidden flex w-full justify-start mt-9 mb-9 max-w-[1170px] max-h-[80vh] rounded-xl max-mc:mt-0 max-mc:mb-0 max-mc:flex-col h-full max-mc:rounded-none`}
      
       */}
      <section
        style={{
          paddingTop: windowWidth < 960 ? navBarHeader : 0,
          paddingBottom: windowWidth < 960 ? navBar : 0
        }}
        className={`overflow-hidden flex w-full justify-start mt-9 mb-9 max-w-[1170px] rounded-xl max-mc:mt-0 max-mc:mb-0 max-mc:flex-col h-[85vh] max-mc:rounded-none`}>
        <div
          className={`px-4 pt-4  bg-backgroundTwo ${
            window.innerWidth < 960 ? "w-[100%]" : "w-[62%]"
          }`}>
          <div
            className={`max-h-[auto] w-full ${
              windowWidth < 960 ? "h-[80vh]" : "h-full"
            }`}>
            <DocumentViewer
              className="w-full h-full"
              src={`${
                previewDeclarationImg !== null
                  ? `<div><img class="select-none pointer-events-none" src="data:image/jpeg;base64, ${previewDeclarationImg}" alt="${selectedDeclarationOption.label}" /></div>`
                  : `<div class="w-full h-[74vh]"></div>`
              }`}
              blockedStatus={!emissionStatus}
              bottomButton={
                <ButtonPrimary
                  optionalDisabledColor={`${certifier.colors.background.backgroundTwo}B3"`}
                  textButton="Emitir"
                  desktopWidth="20%"
                  mobileWidth="100%"
                  optionalMovingIcon={
                    <HiMiniArrowDownTray className="text-[1rem]" />
                  }
                  disabled={!emissionStatus}
                  verifyOnClick={() =>
                    downloadDeclaration({
                      matriculationId: selectedCourseOption.matriculationId,
                      courseId: selectedCourseOption.courseId,
                      type: selectedDeclarationOption.value,
                      name: `${selectedDeclarationOption.label} - ${selectedCourseOption.label} - ${certifier.generalSettings.shortName}`,
                      sessionId: studentInfos.sessionId
                    })
                  }
                />
              }
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: windowWidth < 960 ? navBarHeader : "4rem",
            paddingBottom: windowWidth < 960 ? navBar * 2 + 10 : "4rem"
          }}
          className={`${
            windowWidth < 960 && mobileScreen === 1
              ? "transition-all duration-300 absolute p-6 w-[85%] justify-start overflow-x-hidden z-[101] -translate-x-[100%]"
              : windowWidth < 960 && mobileScreen === 2
              ? "transition-all duration-300 absolute p-6 w-[85%] justify-start overflow-x-hidden z-[101]"
              : "p-16 w-[38%]"
          } flex flex-col h-full gap-y-8 m-0 overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background bg-backgroundThree`}>
          <div>
            <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
              Declarações
            </h1>
          </div>
          <div className="flex flex-col gap-y-3 w-full">
            <label className="span-small-white">Selecione o curso</label>
            <SelectElement
              options={coursesOptions}
              value={selectedCourseOption.value}
              onChange={handleSelectCourseChange}
            />
          </div>
          <div className="flex flex-col gap-y-3 w-full">
            <label className="span-small-white">Selecione a declaração</label>
            <SelectElement
              options={declarationOptions}
              disableGroups
              value={selectedDeclarationOption.value}
              onChange={handleSelectDeclarationChange}
            />
          </div>
          {!emissionStatus && (
            <>
              <span className="span-small-white">Pendências</span>
              <StepsList steps={stepOptions} />
            </>
          )}
          {!emissionStatus && stepStatus[0] && window.innerWidth > 960 && (
            <div className="w-full flex justify-center">
              <Link to={stepStatus[0].routerButton}>
                <ButtonPrimary
                  disableSubmit
                  textButton="Regularizar"
                  desktopWidth={150}
                />
              </Link>
            </div>
          )}
        </div>
      </section>
      <div
        style={{
          marginBottom: `${window.innerWidth <= 960 ? navBar : 0}px`,
          height: `${window.innerWidth <= 960 ? navBar : 0}px`
        }}
        className="hidden max-mc:flex z-[102] fixed bottom-0 gap-5 bg-backgroundThree justify-center items-center w-full">
        {/* X button */}
        <div className="w-[0px] flex justify-center items-center absolute left-0 pl-[10vw]">
          <div
            className={`bar ${mobileScreen === 2 && "checked"}`}
            onClick={handleOpenInfo}>
            <span className="top" />
            <span className="middle" />
            <span className="bottom" />
          </div>
        </div>
        <div className="w-[50%]">
          {!emissionStatus && stepStatus[0] ? (
            <Link to={stepStatus[0].routerButton}>
              <ButtonPrimary
                disableSubmit
                textButton="Regularizar"
                desktopWidth={150}
                mobileWidth="100%"
              />
            </Link>
          ) : (
            <ButtonPrimary
              optionalDisabledColor={`${certifier.colors.background.backgroundTwo}B3"`}
              textButton="Emitir"
              desktopWidth="20%"
              mobileWidth="100%"
              optionalMovingIcon={
                <HiMiniArrowDownTray className="text-[1rem]" />
              }
              disabled={!emissionStatus}
              verifyOnClick={() =>
                downloadDeclaration({
                  matriculationId: selectedCourseOption.matriculationId,
                  courseId: selectedCourseOption.courseId,
                  type: selectedDeclarationOption.value,
                  name: `${selectedDeclarationOption.label} - ${selectedCourseOption.label} - ${certifier.generalSettings.shortName}`,
                  sessionId: studentInfos.sessionId
                })
              }
            />
          )}
        </div>
      </div>
    </main>
  );
}

export default verifyAuth(DeclarationsPage);
