import axios from "axios";

export const serverTime = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/time`);
    return {
      status: response.status,
      data: response.data.data
    };
  } catch (err) {
    return { status: err };
  }
};
